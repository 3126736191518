import { AbstractControl, ValidatorFn } from '@angular/forms';

export function repeatPasswordValidator(originalPasswordControlName: string): ValidatorFn {
    return (formControl: AbstractControl) => {
        const password = formControl.parent &&
            formControl.parent.controls &&
            formControl.parent.controls[originalPasswordControlName] &&
            formControl.parent.controls[originalPasswordControlName].value;
        const passwordConfirmation = formControl.value;
        return password === passwordConfirmation ? null : { passwordsNotEqual: true };
    }

}

export function isDni(): ValidatorFn {
    return (formControl: AbstractControl) => {
        const dni = formControl.value;
        const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
        const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
        const str = dni.toString().toUpperCase();
        if (!nifRexp.test(str) && !nieRexp.test(str)) {
            return {notDni: true};
        }
        const nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');
        const letter = str.substr(-1);
        const charIndex = parseInt(nie.substr(0, 8)) % 23;

        if (validChars.charAt(charIndex) === letter) {
        return null;
        }
        return {notDni: true};
    };
}
