import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UserService } from '../services/user.service';


@Injectable()
export class UserTypeGuard implements CanActivate {

    constructor(
        private navController: NavController,
        private userService: UserService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const userKind = this.userService.getUser().kind;
        if (userKind !== route.data.userKind) {
            this.navController.navigateRoot('/logout');
            return false;
        } else {
            return true;
        }
    }





}